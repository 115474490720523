import React from 'react';
import {injectIntl} from "react-intl";
import {TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import {STRING_IDS} from "../../constants/Strings";
import {ROUTING} from "../../constants/Routing";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {getFormattedString} from "../utils/Utils";

const AppNavigationView = (props) => {

    const handleBackButtonPress = () => {
        const location = window.location;
        const path = location.hash.substring(1);

        if(path === ROUTING.CREATE_NOTIFICATION ||
        path === ROUTING.LIST_NOTIFICATION) {
            // If redirected from another PWA, return to previous PWA page, else exit to native.
            if (props.history.length > 2) {
                // Redirecting to PWA from another PWA adds 2 stacks in browser history.
                props.history.go(-2);
            } else {
                NativeMeshInteractor.exitModule();
            }
        } else {
            props.history.goBack();
        }
    }

    return (
        <TopNavigationView
            title={getFormattedString(props, STRING_IDS.ASK_FOR_HELP)}
            onMenuButtonClicked={handleBackButtonPress}
            optionsMenu={[]}/>
    )
}

export default injectIntl(AppNavigationView);