export const COMMON_STRINGS = {
    ASK_FOR_HELP : "Ask for help",
    NEED_HELP_NOW: "Need help now?",
    SELECT_REASON: "Select reason",
    TEAM_LIFT: "Team lift",
    SPILL: "Spill",
    INJURY: "Injury",
    NOT_ME: "Not me",
    WHO_NEEDS_HELP: "Who needs help",
    LOCATION_HELP_TEXT: "Add location in the text field or scan the nearest sort zone or staging location's code",
    EDIT: "Edit",
    SCAN_ASSOCIATE_BADGE: "Scan associate badge",
    ENTER_ASSOCIATE_ALIAS: "Enter associate alias",
    DONT_HAVE_BADGE: "Don't have badge?",
    SCAN_NEAREST_LOCATION_QR_CODE : "Scan the nearest sort zone or staging location",
    DONT_SEE_CODE: "Don't see code?",
    ADD_RECOGNIZABLE_LOCATION: "Add easily recognizable location",
    DEFAULT: "Default",
    SCAN_LOCATION: "Scan location",
    TEAM_LIFT_ALERT: "Check if anybody you can ask for help near-by before submitting",
    DELIVERED: "Delivered",
    UTR: "UTR",
    WORK_ITEMS_VISIBILITY: "workitemsvisibility",
    HELP_REQUEST: "HELP_REQUEST",
    DOLPHIN: "Dolphin",
    HELP_IS_ON_THE_WAY: "Help is on the way",
    YOU_HAVE_NO_NOTIFICATIONS: "You have no new notifications!",
    SUBMIT: "Submit"
}

export const REASON = {
    AMZLStations_Help_Request_Team_Lift: { id: "AMZLStations_Help_Request_Team_Lift", label: "team_lift", scc_label: "team_lift"},
    AMZLStations_Help_Request_Spill: { id: "AMZLStations_Help_Request_Spill", label: "spill", scc_label: "spill" },
    AMZLStations_Help_Request_Injury: { id: "AMZLStations_Help_Request_Injury", label: "injury", scc_label: "injury"}
}

export const CLIENT_ID = {
    DOLPHIN: "Dolphin",
    SCC: "SCC"
}

export const NOTIFICATION_STRINGS = {
    UNABLE_TO_READ_BARCODE: "Unable to read barcode. Please try again!",
    HELP_REQUEST_SUBMIT_SUCCESS: "Your help request was submitted successfully. Help is on the way!",
    HELP_REQUEST_SUBMIT_FAILURE: "There was a problem submitting the help request. Please try again!",
}

export const CREATE_HELP_REQUEST_DATA = {
    AMZL_STATIONS: "AMZLStations",
}

export const SEVERITY = {
    HIGH: "HIGH"
}

export const SLA = {
    THIRTY_MINS : 1000 * 60 * 30 //in millis.
}

export const STRING_IDS = {
    ASK_FOR_HELP: "ask_for_help",
    NEED_HELP_NOW: "need_help_now",
    SELECT_REASON: "select_reason",
    TEAM_LIFT: "team_lift",
    SPILL: "spill",
    INJURY: "injury",
    SOMETHING_ELSE: "something_else",
    WHO_NEEDS_HELP: "who_needs_help",
    SCAN_LOCATION: "scan_location",
    ADD_RECOGNIZABLE_LOCATION: "add_recognizable_location",
    LOCATION_DESC: "location_desc",
    NOT_ME: "not_me",
    SCAN: "scan",
    SCAN_THEIR_BADGE: "scan_their_badge",
    DONT_HAVE_BADGE: "dont_have_badge",
    ENTER_THEIR_ALIAS: "enter_their_alias",
    SUBMIT: "submit",
    REQUEST_SENT_CONTINUE_WORKING: "request_sent_continue_working",
    REQUEST_SENT_REST: "request_sent_rest",
    REQUEST_SENT_REST_LONG : "request_sent_rest_long",
    SCAN_NEAREST_LOCATION_QR: "scan_nearest_location_qr",
    DONT_SEE_CODE: "dont_see_code",
    TEAM_LIFT_WARNING: "team_lift_warning",
    ACK_MESSAGE: "ack_message",
    YOU_HAVE_NO_NOTIFICATIONS: "you_have_no_notifications",
    SEND: "send",
    HELP_IS_ON_THE_WAY: "help_is_on_the_way",
    SCC_MESSAGE_CARE: "scc_message_care",
    SCC_MESSAGE_SUPPORT:"scc_message_support",
    RESET_LOCATION: "reset_location"
}